import React from "react"

import Layout from "../layout/layout"
import SEO from "../components/seo"
import Top from "../components/section-top"
import About from "../components/section-about"
import StrongPoint from "../components/section-strong-points"
import Worries from "../components/section-worries"
import Benefit from "../components/section-benefit"
import Simulation from "../components/section-simulation"
import Feature from "../components/section-feature"
import Example from "../components/section-example"
import QA from "../components/section-qa"

export default function IndexPage() {
  return (
    <Layout>
      <SEO />
      <section id="top">
        <Top />
      </section>
      <section id="about">
        <About/>
      </section>
      <section id="strong-points">
        <StrongPoint/>
      </section>
      <section id="worries">
        <Worries/>
      </section>
      <section id="benefit">
        <Benefit />
      </section>
      <section id="simulation">
        <Simulation />
      </section>
      <section id="feature">
        <Feature />
      </section>
      <section id="example">
        <Example />
      </section>
      <section id="qa">
        <QA />
      </section>
    </Layout>
  )
}
