// extracted by mini-css-extract-plugin
export var container = "section-example-module--container--ZY0CW";
export var contents = "section-example-module--contents--poB4Z";
export var list = "section-example-module--list--geTRS";
export var item = "section-example-module--item--XtiS1";
export var item_title = "section-example-module--item_title--upEow";
export var item_desc = "section-example-module--item_desc--4fEs1";
export var item_worries = "section-example-module--item_worries--DGkot";
export var item_approach = "section-example-module--item_approach--m5PBC";
export var item_detail = "section-example-module--item_detail---5owQ";
export var item_effect = "section-example-module--item_effect--3hhy8";
export var mgb4 = "section-example-module--mgb4--jQPtK";
export var mgt4 = "section-example-module--mgt4--0D6be";
export var mgt8 = "section-example-module--mgt8--oQi2h";