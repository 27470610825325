// extracted by mini-css-extract-plugin
export var container = "section-qa-module--container--GOEr6";
export var contents = "section-qa-module--contents--5ptWb";
export var list = "section-qa-module--list--c1kvq";
export var item = "section-qa-module--item--1QxQO";
export var question = "section-qa-module--question--4NEwh";
export var question_text = "section-qa-module--question_text--iadFA";
export var answer = "section-qa-module--answer--o3v5n";
export var answer_text = "section-qa-module--answer_text--S1lXS";
export var answer_expand = "section-qa-module--answer_expand--eAPJn";
export var answer_toggle_img = "section-qa-module--answer_toggle_img--SV55u";
export var answer_toggle_img_expand = "section-qa-module--answer_toggle_img_expand---m9Aq";
export var buttons = "section-qa-module--buttons--DF5HS";