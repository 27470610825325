// extracted by mini-css-extract-plugin
export var container = "section-worries-module--container--+cSBY";
export var contents = "section-worries-module--contents--h7Wlg";
export var title = "section-worries-module--title--Vnlon";
export var worries_lists = "section-worries-module--worries_lists--5qkMh";
export var item = "section-worries-module--item--tzCFp";
export var item_list = "section-worries-module--item_list--peTYe";
export var item_title = "section-worries-module--item_title--iZcVs";
export var worries_people_wrapper = "section-worries-module--worries_people_wrapper--J1+Fc";
export var worries_people = "section-worries-module--worries_people---hChd";
export var triangle = "section-worries-module--triangle--hXSXu";
export var resolve = "section-worries-module--resolve--xhZ6O";
export var points = "section-worries-module--points--Frvgq";
export var point = "section-worries-module--point--LIlC-";
export var point_title = "section-worries-module--point_title--H+sX1";
export var point_description = "section-worries-module--point_description--D2RLc";