import React from "react"
import * as styles from "./section-feature.module.scss"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import SectionTitle from "./section-title"

import AnimScroll from "./anim-scroll"
import ImgOutsourcing from "../images/icon-outsourcing.png"
import ImgRefinancing from "../images/icon-refinancing.png"
import ImgCentralizedManagement from "../images/icon-centralized-management.png"
import ImgAcquisitionAgency from "../images/icon-acquisition-agency.png"

/**
 * 機能詳細
 */
export default function Feature() {
  return (
    <div className={styles.container}>
      <div className={styles.contents}>
          <SectionTitle title="機能詳細" />
          <div className={styles.list}>
            <Item
              src={ImgOutsourcing}
              title={
                <>
                  駐車場探しから契約まで
                  <br/>
                  アウトソーシング
                </>
              }
              desc={
                <>
                  ご条件（エリア、徒歩分数、賃料、車両サイズ等）をいただければ、ご希望にあった駐車場をいくつかピックアップしご紹介いたします。
                  <br/>
                  全国の不動産管理会社・コインパーキング事業者と提携しているため、迅速な発見が可能です。
                </>
              }
            />
            <Item
              src={ImgRefinancing}
              title="駐車場借り換え支援"
              desc={
                <>
                  「より安い」「より近い」駐車場への借り換えで、コスト削減や業務効率化（移動時間の短縮など）を実現することが可能です。
                  <br/>
                  業務負荷の高い借り換え業務を支援します。
                </>
              }
            />
            <Item
              src={ImgCentralizedManagement}
              title="駐車場の契約情報・支払い先を一元管理"
              desc={
                <>
                  管理画面から契約書や車検証、支払い情報などを一元管理。
                  <br/>
                  明細をエクスポートして会計・経理ソフトに取り込むことが可能です。
                </>
              }
            />
            <Item
              src={ImgAcquisitionAgency}
              title="保管場所使用承諾書の取得代行"
              desc="委任状をいただくことで貴社に代わって、駐車場管理会社に対し、①保管場所使用承諾書の取得代行を実施し、また同時に必要となる②保管場所の所在図・配置図を手配をした上で、貴社へ①・②をまとめて送付いたします。"
            />
          </div>
      </div>
    </div>
  )
}

let index = 0

function Item({ src, title, desc }) {
  const breakpoints = useBreakpoint()
  let delay = (index % 2) * 200
  index++
  return (
    <AnimScroll className={styles.item} animDelay={breakpoints.sp ? 0 : delay}>
      <p className={styles.item_title}>{title}</p>
      <div className={styles.item_icon}>
        <img src={src} alt="説明アイコン" />
      </div>
      <p className={styles.item_desc}>{desc}</p>
    </AnimScroll>
  )
}
