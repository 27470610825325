import React from "react"
import * as styles from "./section-example.module.scss"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import SectionTitle from "./section-title"
import AnimScroll from "./anim-scroll"

import ImgCheckIcon from "../images/icon-check.png"
import ImgExampleHeader01PC from "../images/img-example-header01_pc.png"
import ImgExampleHeader02PC from "../images/img-example-header02_pc.png"
import ImgExampleHeader03PC from "../images/img-example-header03_pc.png"
import ImgExampleHeader01SP from "../images/img-example-header01_sp.png"
import ImgExampleHeader02SP from "../images/img-example-header02_sp.png"
import ImgExampleHeader03SP from "../images/img-example-header03_sp.png"
import ImgExample01 from "../images/img-example-01.png"
import ImgExample02 from "../images/img-example-02.png"
import ImgExample03 from "../images/img-example-03.png"

/**
 * 導入事例
 */
export default function Example() {
  const breakpoints = useBreakpoint()
  return (
    <div className={styles.container}>
      <div className={styles.contents}>
        <SectionTitle title="導入事例" />
        <div className={styles.list}>
          <AnimScroll className={styles.item}>
            <div className={styles.item_title}>
              <img src={ImgExample01} alt="01" />
              <p>A社<span>（管理台数：約1000台）</span>の場合</p>
            </div>
            <div className={styles.item_desc}>
              <div className={styles.item_worries}>
                {breakpoints.sp ? (
                  <img src={ImgExampleHeader01SP} alt="導入前のお悩み"/>
                ):(
                  <img src={ImgExampleHeader01PC} alt="導入前のお悩み"/>
                )}
                <div>
                  <p>
                    <span className={styles.mgb4}>直行直帰で</span>
                    駐車場の契約・解約が増加
                    <br/>
                    （繁忙期で月50件程度）
                    <span className={styles.mgt4}>営業、総務、経理の業務時間を圧迫</span>
                  </p>
                </div>
              </div>
              <div className={styles.item_approach}>
                {breakpoints.sp ? (
                  <img src={ImgExampleHeader02SP} alt="アプローチ"/>
                ):(
                  <img src={ImgExampleHeader02PC} alt="アプローチ"/>
                )}
                <div>
                  <p>希望の条件を管理画面に入力するだけで駐車場探しが完了</p>
                  <p className={styles.mgt8}>契約情報の一元管理や支払い先を一本化</p>
                </div>
              </div>
              <div className={styles.item_effect}>
                {breakpoints.sp ? (
                  <img src={ImgExampleHeader03SP} alt="導入後の効果"/>
                ):(
                  <img src={ImgExampleHeader03PC} alt="導入後の効果"/>
                )}
                <ul>
                  <li>
                    <img src={ImgCheckIcon} alt="チェックアイコン"/>
                    <p>
                      月200時間の業務時間を削減<br/>（全部署合計）
                      <br/>
                      <span className={styles.mgt8}>営業現場も本業に集中できるように！</span>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </AnimScroll>
          <AnimScroll
            className={styles.item}
            animDelay={breakpoints.sp ? 0 : 200}
          >
            <div className={styles.item_title}>
              <img src={ImgExample02} alt="02" />
              <p>B社<span>（管理台数：約200台）</span>の場合</p>
            </div>
            <div className={styles.item_desc}>
              <div className={styles.item_worries}>
                {breakpoints.sp ? (
                  <img src={ImgExampleHeader01SP} alt="導入前のお悩み"/>
                ):(
                  <img src={ImgExampleHeader01PC} alt="導入前のお悩み"/>
                )}
                <div>
                  <p>
                    <span className={styles.mgb4}>駐車場コストの見直しのため、</span>
                    より安く便利な駐車場へ
                    <br/>
                    借り換えたい
                  </p>
                </div>
              </div>
              <div className={styles.item_approach}>
                {breakpoints.sp ? (
                  <img src={ImgExampleHeader02SP} alt="アプローチ"/>
                ):(
                  <img src={ImgExampleHeader02PC} alt="アプローチ"/>
                )}
                <div>
                  <span className={styles.mgb4}>Park Direct が調査した結果、</span>
                  <p className={styles.mgb4}>半数以上の駐車場でコスト削減が可能と判明</p>
                  {breakpoints.sp ? (
                    <p className={styles.item_detail}>事務所により近く、<br/>より安い駐車場を確保</p>
                  ):(
                    <p className={styles.item_detail}>事務所により近く、より安い駐車場を確保</p>
                  )}
                </div>
              </div>
              <div className={styles.item_effect}>
                {breakpoints.sp ? (
                  <img src={ImgExampleHeader03SP} alt="導入後の効果"/>
                ):(
                  <img src={ImgExampleHeader03PC} alt="導入後の効果"/>
                )}
                <ul>
                  <li>
                    <img src={ImgCheckIcon} alt="チェックアイコン"/>
                    <p>
                      利便性が向上した
                    </p>
                  </li>
                  <li>
                    <img src={ImgCheckIcon} alt="チェックアイコン"/>
                    <p>
                      年間数百万円のコスト削減の可能性
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </AnimScroll>
          <AnimScroll
            className={styles.item}
            animDelay={breakpoints.sp ? 0 : 400}
          >
            <div className={styles.item_title}>
              <img src={ImgExample03} alt="03" />
              <p>C社<span>（管理台数：約10台）</span>の場合</p>
            </div>
            <div className={styles.item_desc}>
              <div className={styles.item_worries}>
                {breakpoints.sp ? (
                  <img src={ImgExampleHeader01SP} alt="導入前のお悩み"/>
                ):(
                  <img src={ImgExampleHeader01PC} alt="導入前のお悩み"/>
                )}
                <div>
                  <span className={styles.mgb4}>事務所移転に伴い、</span>
                  <ul>
                    <li>都心主要駅</li>
                    <li>徒歩３分圏内</li>
                    <li>車両10台分の駐車場</li>
                  </ul>
                  <span className={styles.mgt4}>が必要</span>
                </div>
              </div>
              <div className={styles.item_approach}>
                {breakpoints.sp ? (
                  <img src={ImgExampleHeader02SP} alt="アプローチ"/>
                ):(
                  <img src={ImgExampleHeader02PC} alt="アプローチ"/>
                )}
                <div>
                  <span className={styles.mgb4}>
                    Park Direct for Businessが
                    <br/>
                    事業者と交渉
                  </span>
                  <p className={styles.mgb4}>コインパーキングから月極駐車場への変更を促した</p>
                  <p className={styles.item_detail}>必要区画の確保に成功！</p>
                </div>
              </div>
              <div className={styles.item_effect}>
                {breakpoints.sp ? (
                  <img src={ImgExampleHeader03SP} alt="導入後の効果"/>
                ):(
                  <img src={ImgExampleHeader03PC} alt="導入後の効果"/>
                )}
                <ul>
                  <li>
                    <img src={ImgCheckIcon} alt="チェックアイコン"/>
                    <p>
                      駐車場探し〜契約まですべてお任せできた
                    </p>
                  </li>
                  <li>
                    <img src={ImgCheckIcon} alt="チェックアイコン"/>
                    <p>
                      約２週間で契約締結まで完了
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </AnimScroll>
        </div>
      </div>
    </div>
  )
}
