import React from "react"
import * as styles from "./section-simulation.module.scss"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import DemoButton from "./button-demo"

/**
 * ファーストビューで表示されるエリア
 */
export default function Simulation() {
  const breakpoints = useBreakpoint()
  return (
    <div className={styles.container}>
      <div className={styles.contents}>
        <div className={styles.phrase}>
          <p className={styles.title}>無料シミュレーション</p>
          {breakpoints.sp ? (
            <p className={styles.description}>
              借り換えによるコスト削減効果や導入後の業務負荷削減効果のシミュレーションを無料で承ります。導入検討のご参考にご利用ください。ご希望の方は、お問い合わせフォームよりお問い合わせください。
            </p>
          ):(
            <p className={styles.description}>
              借り換えによるコスト削減効果や
              <br/>
              導入後の業務負荷削減効果のシミュレーションを無料で承ります。
              <br/>
              導入検討のご参考にご利用ください。
              <br/>
              ご希望の方は、お問い合わせフォームよりお問い合わせください。
            </p>
          )}
        </div>
        <div className={styles.buttons}>
            <DemoButton />
        </div>
        <div className={styles.background} />
      </div>
    </div>
  )
}
