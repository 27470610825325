import React from "react"
import * as styles from "./section-strong-points.module.scss"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import AnimScroll from "./anim-scroll"

import ImgLineLeft from "../images/img-line_left.png";
import ImgLineRight from "../images/img-line_right.png";

/**
 * 導入のメリット
 */
export default function StrongPoints() {
  const breakpoints = useBreakpoint()
  return (
    <div className={styles.container}>
      <div className={styles.contents}>
        <AnimScroll className={styles.title} animType="fade" animDelay={breakpoints.sp ? 200 : 600}>
            <img src={ImgLineLeft} alt="" />
            <p>Park Directの強み</p>
            <img src={ImgLineRight} alt="" />
        </AnimScroll>
        <div className={styles.list}>
            <AnimScroll className={styles.item}>
                <div>
                    <p>駐車場管理のプロ<br/>導入台数は数十万台！</p>
                </div>
            </AnimScroll>
            <AnimScroll
            className={styles.item}
            animDelay={breakpoints.sp ? 0 : 200}
            >
                <div>
                    <p>全国の管理会社様との<br/>つながり</p>
                </div>
            </AnimScroll>
            <AnimScroll
            className={styles.item}
            animDelay={breakpoints.sp ? 0 : 400}
            >
                <div>
                    <p>駐車場探し・手続き・<br/>管理業務のノウハウ</p>
                </div>
            </AnimScroll>
        </div>
      </div>
    </div>
  )
}

