import React from "react"
import * as styles from "./section-benefit.module.scss"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import SectionTitle from "./section-title"
import AnimScroll from "./anim-scroll"

import ImgMerit01 from "../images/img-merit01.svg"
import ImgMerit02 from "../images/img-merit02.svg"
import ImgMerit03 from "../images/img-merit03.svg"
import ImgMerit01Number from "../images/img-merit01-number.png"
import ImgMerit02Number from "../images/img-merit02-number.png"
import ImgMerit03Number from "../images/img-merit03-number.png"

/**
 * 導入のメリット
 */
export default function Benefit() {
  const breakpoints = useBreakpoint()
  return (
    <div className={styles.container}>
      <div className={styles.contents}>
        <SectionTitle title="導入メリット" />
        <div className={styles.list}>
        {breakpoints.sp ? (
          <Item
            img_number={ImgMerit01Number}
            img_number_alt="01"
            img_desc={ImgMerit01}
            title={
              <>
              駐車場関連の業務負荷
              <br/>
              ／コスト削減
              </>
            }
            desc={
              <>
              申込から契約までのお手続きも現地に行かずに完結。 駐車場関連の人件費や諸経費を大幅圧縮して業務負荷／コスト削減を実現します。 
              <br/>
              Park Directに掲載されていない駐車場の契約・管理も可能です。
              </>
            }
          />
        ):(
          <Item
            img_number={ImgMerit01Number}
            img_number_alt="01"
            img_desc={ImgMerit01}
            title="駐車場関連の業務負荷／コスト削減"
            desc={
              <>
              申込から契約までのお手続きも現地に行かずに完結。 駐車場関連の人件費や諸経費を大幅圧縮して業務負荷／コスト削減を実現します。 
              <br/>
              Park Directに掲載されていない駐車場の契約・管理も可能です。
              </>
            }
          />
        )}
           <Item
            img_number={ImgMerit02Number}
            img_number_alt="02"
            img_desc={ImgMerit02}
            title="契約・支払い先を一元管理"
            desc={
              <>
              契約・支払い先をPark Direct for Businessで一元管理することで、契約更新や解約の漏れを防止。
              <br/>
              各口座へ振り込む必要がなく、Park Direct for Businessへの一括支払いのみで完了。
              </>
            }
          />
           <Item
            img_number={ImgMerit03Number}
            img_number_alt="03"
            img_desc={ImgMerit03}
            title="駐車場関連のガバナンスを強化"
            desc={
              <>
              請求書が発行されない場合もPark Direct for Businessならインボイス制度に対応可能。
              <br/>
              各支店の駐車場契約状況を全体把握できるため社内のガバナンス強化にも活用できます。
              </>
            }
          />
        </div>
      </div>
    </div>
  )
}

let index = 0

function Item({ img_number, img_number_alt, img_desc, title, desc }) {
  const breakpoints = useBreakpoint()
  let delay = (index % 2) * 200
  index++
  return (
  <AnimScroll className={styles.item} animDelay={breakpoints.sp ? 0 : delay}>  
    <div>
      <div className={styles.item_number}>
        <img src={img_number} alt={img_number_alt}/>
      </div>
      <p className={styles.item_title}>{title}</p>
      <div className={styles.item_content}>
        <img className={styles.item_img} src={img_desc} alt="説明画像" />
        <p className={styles.item_desc}>{desc}</p>
      </div>
    </div>
  </AnimScroll>
  )
}
