import React from "react"
import * as styles from "./section-title.module.scss"

import AnimScroll from "./anim-scroll"

export default function SectionTitle({ title }) {
  return (
    <AnimScroll>
      <div className={styles.container}>
        <p className={styles.title}>{title}</p>
      </div>
    </AnimScroll>
  )
}
