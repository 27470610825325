import React from "react"
import * as styles from "./section-worries.module.scss"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import AnimScroll from "./anim-scroll"

import ImgWorriesPeople1PC from "../images/img-worries_people1_pc.svg"
import ImgWorriesPeople2PC from "../images/img-worries_people2_pc.svg"
import ImgWorriesPeople1SP from "../images/img-worries_people1_sp.svg"
import ImgWorriesPeople2SP from "../images/img-worries_people2_sp.svg"

/**
 * 詳細な機能のご紹介
 */
export default function Worries() {
  const breakpoints = useBreakpoint()
  return (
    <div className={styles.container}>
        <div className={styles.contents}>
            <AnimScroll animType="fade" animDelay={breakpoints.sp ? 200 : 600}>
                <p className={styles.title}>業務用車両の駐車場管理に関する<br/>こんな<span>“</span>お悩み<span>”</span>はありませんか？</p>
            </AnimScroll>
            <div className={styles.worries_lists}>
                <Item
                title="駐車場探し"
                item_1="駐車場がなかなか見つからない"
                item_2="現地を歩いて探す時間がない"
                item_3="電話で空車確認するのが煩わしい"
                />
                <Item
                title="申込〜契約"
                item_1="手続きが煩雑（郵送・現地契約）"
                item_2="社内規定に合うかどうかの確認が必要"
                item_3="反社チェックが必要"
                />
                <Item
                title="支払い・管理"
                item_1="請求書が発行されない"
                item_2="契約毎に違う支払条件・振込先にするのが面倒"
                item_3="保管場所使用承諾書の手配や更新・解約が面倒"
                />
                <Item
                title="ガバナンス"
                item_1="インボイス制度に対応していない"
                item_2="契約情報が集約化されていない"
                item_3="ガバナンスチェックに対応していない"
                />
            </div>
            {breakpoints.sp ? (
              <AnimScroll className={styles.worries_people_wrapper}>
                <img className={styles.worries_people} src={ImgWorriesPeople1SP} alt="困っている人"/>
              </AnimScroll>
            ):(
              <AnimScroll>
                <img className={styles.worries_people} src={ImgWorriesPeople1PC} alt="困っている人"/>
              </AnimScroll>
            )}
            <AnimScroll>
              <div className={styles.triangle}></div>
            </AnimScroll>
            {breakpoints.sp ? (
              <AnimScroll>
                <p className={styles.resolve}>Park Direct for Businessなら<br/>すべて解決します！</p>
              </AnimScroll>
            ):(
              <AnimScroll>
                <p className={styles.resolve}>Park Direct for Businessならすべて解決します！</p>
              </AnimScroll>
            )}
            <div className={styles.points}>
              <Point
                title="POINT 01"
                description={
                  <>
                    駐車場関連の
                    <br />
                    業務負荷／コスト削減
                  </>
                }
              />
              <Point
                title="POINT 02"
                description={
                  <>
                    契約・支払い先を
                    <br />
                    一元管理
                  </>
                }
              />
              <Point
                title="POINT 03"
                description={
                  <>
                    駐車場関連の
                    <br />
                    ガバナンスを強化
                  </>
                }
              />
            </div>
            {breakpoints.sp ? (
              <AnimScroll className={styles.worries_people_wrapper}>
                <img className={styles.worries_people} src={ImgWorriesPeople2SP} alt="困っている人"/>
              </AnimScroll>
            ):(
              <AnimScroll>
                <img className={styles.worries_people} src={ImgWorriesPeople2PC} alt="困っている人"/>
              </AnimScroll>
            )}
      </div>
    </div>
  )
}

let index = 0

function Item({ title, item_1, item_2, item_3 }) {
  const breakpoints = useBreakpoint()
  let delay = (index % 2) * 200
  index++
  return (
    <AnimScroll className={styles.item} animDelay={breakpoints.sp ? 0 : delay}>
      <div className={styles.item_title}>{title}</div>
      <div className={styles.item_list}>
        <p><span>“</span>{item_1}<span>”</span></p>
        <p><span>“</span>{item_2}<span>”</span></p>
        <p><span>“</span>{item_3}<span>”</span></p>
      </div>
    </AnimScroll>
  )
}

function Point({ title, description }) {
  const breakpoints = useBreakpoint()
  let delay = (index % 2) * 200
  index++
  return (
    <AnimScroll className={styles.point} animDelay={breakpoints.sp ? 0 : delay}>
      <p className={styles.point_title}>{title}</p>
      <p className={styles.point_description}>{description}</p>
    </AnimScroll>
  )
}
