import React from "react"
import * as styles from "./section-about.module.scss"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import AnimScroll from "./anim-scroll"

import ImgTitleAboutPC from "../images/title-about_pc.png"
import ImgTitleAboutSP from "../images/title-about_sp.png"
import ImgSystem from "../images/img-system.png"

/**
 * About Park Direct for Business
 */
export default function About() {
  const breakpoints = useBreakpoint()
  return (
    <div className={styles.container}>
       <div className={styles.contents}>
          {breakpoints.sp ? (
            <div className={styles.about}>
              <AnimScroll className={styles.description} animType="fade">
                <img src={ImgTitleAboutSP} alt="Park Direct for Businessは、導入実績No.1のPark Directが提供する法人車両用駐車場管理システムです。"/>
              </AnimScroll>
              <AnimScroll>
                <div className={styles.usage}>
                    <div>
                        <p>駐車場に関する業務の</p>
                        <div>
                            <p>検索</p>
                            <p>契約</p>
                            <p>管理</p>
                            <p>支払</p>
                        </div>
                    </div>
                    <p>すべてをオンライン化・アウトソーシングで<br/>大幅に効率化！</p>
                </div>
              </AnimScroll>
              <AnimScroll className={styles.system_image}>
                <img src={ImgSystem} alt="システム画面" />
              </AnimScroll>
          </div>
          ):(
          <div className={styles.about}>
              <AnimScroll className={styles.description} animType="fade">
                <img src={ImgTitleAboutPC} alt="Park Direct for Businessは、導入実績No.1のPark Directが提供する法人車両用駐車場管理システムです。"/>
              </AnimScroll>
              <AnimScroll>
                <div className={styles.usage}>
                    <div>
                        <p>駐車場に関する業務の</p>
                        <div>
                            <p>検索</p>
                            <p>契約</p>
                            <p>管理</p>
                            <p>支払</p>
                        </div>
                    </div>
                    <p>すべてをオンライン化・アウトソーシングで大幅に効率化！</p>
                </div>
              </AnimScroll>
              <AnimScroll className={styles.system_image}>
                <img src={ImgSystem} alt="システム画面" />
              </AnimScroll>
          </div>
          )}
          <p className={styles.note}>※「月極駐車場のオンライン契約サービス」（駐車場のシェアリングサービス・サブリースは除く）の対象各社への調査。（2023年12月、㈱エクスクリエ調べ） </p>
        </div>
    </div>
  )
}
