import React from "react"
import * as styles from "./section-qa.module.scss"

import SectionTitle from "./section-title"
import DemoButton from "./button-demo"

import AnimScroll from "./anim-scroll"

import ImgArrowRight from "../images/icon-arrow-right.svg"

/**
 * 開閉状態
 */
let isOpenState = new Map()

/**
 * よくあるご質問
 */
export default function QA() {
  return (
    <div className={styles.container}>
      <div className={styles.contents}>
        <SectionTitle title="よくあるご質問" />
        <div className={styles.list}>
          <Item
            id="1"
            question="車両管理BPOとの違いは何ですか？"
            answer="車両管理BPOではカバーできない、駐車場探し、契約・支払い代行、駐車場情報の一元管理が提供可能です。"
          />
          <Item
            id="2"
            question={
              <>
              現在紙などのアナログな管理を行なっています。
              <br/>
              導入・運用開始までにどのくらいの期間を要しますか？
              </>
            }
            answer="ご契約後、最短翌日から運用開始が可能です。"
          />
          <Item
            id="3"
            question="Park Directに掲載されていない駐車場も、Park Direct for Businessで契約できますか？"
            answer="はい、契約可能です。"
          />
          <Item
            id="4"
            question="導入前に契約した駐車場の支払い代行や契約情報の管理もできますか？"
            answer="はい、可能です。"
          />
          <Item
            id="5"
            question="駐車場探しから契約まではどのくらいの期間を要しますか？"
            answer={
              <>
              条件をいただいてから、約2週間ほどで契約が可能です。
              <br/>
              Park Direct掲載物件であれば、最短翌日から駐車場のご利用が可能です。
              </>
            }
          />
          <Item
            id="6"
            question="インボイス制度に対応していますか？"
            answer={
              <>
              はい、対応しています。
              <br/>
              適格請求書を発行いたします。
              </>
            }
          />
        </div>
        <div className={styles.buttons}>
          <DemoButton />
        </div>
      </div>
    </div>
  )
}

function Item({ id, question, answer }) {
  return (
    <button onClick={() => toggle(id)}>
      <AnimScroll>
        <div id={id} className={styles.item}>
          <div className={styles.question}>
            <div className={styles.question_text}>{question}</div>
            <div
              className={
                isOpenState[id]
                  ? styles.answer_toggle_img_expand
                  : styles.answer_toggle_img
              }
            ></div>
          </div>
          <div className={styles.answer}>
            <div className={styles.answer_text}>
              <img src={ImgArrowRight} alt="右矢印"/>
              <p>{answer}</p>
            </div>
          </div>
        </div>
      </AnimScroll>
    </button>
  )
}

/**
 * 開閉
 *
 * @param {アイテムのID} id
 */
function toggle(id) {
  if (isOpenState[id] == null) {
    isOpenState[id] = false
  }

  let item = document.getElementById(id)
  let answer = item.getElementsByClassName(styles.answer)[0]
  let answerToggle = item.getElementsByClassName(styles.answer_toggle_img)[0]
  if (isOpenState[id]) {
    // 開 -> 閉
    answer.classList.remove(styles.answer_expand)
    answerToggle.classList.remove(styles.answer_toggle_img_expand)
  } else {
    // 閉 -> 開
    answer.classList.add(styles.answer_expand)
    answerToggle.classList.add(styles.answer_toggle_img_expand)
  }
  isOpenState[id] = !isOpenState[id]
}
