import React from "react"
import PropTypes from "prop-types"

/**
 * スクロールアニメーション
 *
 * https://www.gatsbyjs.com/plugins/gatsby-plugin-scroll-reveal/
 */
export default function AnimScroll({
  className,
  children,
  animType,
  animDuration,
  animDelay,
  animEasing,
}) {
  return (
    <div
      className={className}
      data-sal={animType}
      data-sal-duration={animDuration}
      data-sal-delay={animDelay}
      data-sal-easing={animEasing}
    >
      {children}
    </div>
  )
}

AnimScroll.propTypes = {
  children: PropTypes.node.isRequired,
}

// デフォルト値
AnimScroll.defaultProps = {
  animType: "slide-up",
  animDuration: 300,
  animDelay: 0,
  animEasing: "ease-in",
}

// 型指定
AnimScroll.propTypes = {
  children: PropTypes.node.isRequired,
  animType: PropTypes.string,
  animDuration: PropTypes.number,
  animDelay: PropTypes.number,
  animEasing: PropTypes.string,
}
